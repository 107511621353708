import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setGuideErrorsChartData = (guideErrorsChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ERRORS_CHART_DATA,
        guideErrorsChartData: guideErrorsChartData
    }
};

export const setGuideErrorsSummary = ( guideErrorsSummary:any) => {
    return {
        type: ActionTypes.SET_GUIDE_ERRORS_DETAIL_SUMMARY,
        guideErrorsSummary:  guideErrorsSummary
    }
}

export const setGuideErrorData = (guideErrorData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_ERROR_DETAILS,
        guideErrorData: guideErrorData
    }
};



export const getGuideErrorsDetailData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('guide-insights/guide-errors-detail/');


        axiosInstance.get(generatedURL)
            .then(res => {

                console.log(res.data.data)
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                    "requestUUID": res.data.requestUUID,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_GUIDE_ERRORS_CHART_DATA:
                            resolve(setGuideErrorsChartData(data));
                            break;

                        case ActionTypes.SET_GUIDE_ERRORS_DETAIL_SUMMARY:
                            resolve(setGuideErrorsSummary(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_ERROR_DETAILS:
                            resolve(setGuideErrorData(data));
                            break;

                        default:
                            break;
                    }
                }
                

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}